<template>
  <template v-if="!isMobileScreen">
    <div class="flex-col hidden md:flex pr-md">
      <div class="flex flex-col w-full font-deco-alt mb-xs">
        <NuxtLink
          :to="'/productlists'"
          class="pl-md flex flex-row items-center hover:text-primary-base text-alt-xl font-bold leading-xs min-h-[36px]"
        >
          {{ t('shop.myProducts.nav.title') }}
        </NuxtLink>
        <div class="border-border-light border-b-1 pb-xs"></div>
      </div>

      <NuxtLink
        v-for="(item, index) of navItems"
        :key="index"
        :to="item.link"
        class="flex flex-row items-center w-full text-alt-lg pl-md py-xs hover:bg-primary-lightest hover:text-text-base leading-xs font-deco-alt"
        :class="{
          'bg-primary-base text-white':
            navStore.mainNav.activeItem.id === item.id,
        }"
      >
        {{ t(item.title) }}
      </NuxtLink>

      <!--individual favorite Lists-->
      <div class="font-deco-alt">
        <hr class="w-full h-2 border-t-2 my-xs" />
        <p class="text-alt-xl font-bold pl-md min-h-[36px]">
          {{ t('product.favorites-list') }}
        </p>
        <p
          v-if="session.isLoggedIn"
          class="font-text pl-md text-primary-base min-h-[36px] cursor-pointer"
          @click="
            () => {
              if (session.isLoggedIn)
                dialogStore.openDialog(DialogIdent.NEW_EDIT_FAVORITE_LIST);
            }
          "
        >
          <FaIcon icon-class="far fa-plus" />
          {{ t('myProducts.sideNav.addNewList') }}
        </p>

        <NuxtLink
          v-for="(item, index) of computedFavNavItems"
          :key="index"
          :to="item.link"
          class="flex flex-row items-center w-full text-alt-lg pl-md py-xs hover:bg-primary-lightest hover:text-text-base leading-xs"
          :class="{
            'bg-primary-base text-white':
              useRouter().currentRoute?.value.path === item.link,
          }"
        >
          {{ t(item.title) }}
        </NuxtLink>
      </div>
      <div id="favListFilterRoot"></div>
    </div>
  </template>
  <template v-else-if="isMobileScreen">
    <NuxtLink to="/shop" class="font-deco-alt">
      {{ t('shop.myProducts.mobileSideNav.shop') }}
      <span class="pr-2xs">
        <FaIcon icon-class="fal fa-arrow-right" />
      </span>
    </NuxtLink>
    <NuxtLink to="/productlists" class="font-deco-alt">
      {{ t('navigation.myProducts.myProducts') }}
      <span class="pr-2xs">
        <FaIcon icon-class="fal fa-arrow-right" />
      </span>
    </NuxtLink>
    <NuxtLink
      v-for="(favList, index) of computedMobileNav"
      v-show="useRouter().currentRoute.value.path === favList.link"
      :key="index"
      :to="favList.link"
      class="font-deco-alt"
    >
      <span
        :class="{
          'text-primary-base':
            useRouter().currentRoute.value.path === favList.link,
        }"
      >
        {{ t(favList.title) }}
      </span>
    </NuxtLink>
  </template>
</template>
<script setup lang="ts">
import { useNav } from '~/stores/useNav';
import { MyProductIds } from '@/@types/navigationIds';
import FaIcon from '@/components/fa-icon.vue';
import { mq_breakpointIsMobile } from '@/injectionSymbols';
import { useFavLists } from '~/stores/useFavLists';
import { useDialogStore, DialogIdent } from '~/stores/useDialogStore';
import { transformStringForUrl } from '~/helpers/shop';
import type { MyProductsNavType } from './useProductlistsSideNav';
import { useSessionStore } from '~/stores/useSessionStore';

const isMobileScreen = inject(mq_breakpointIsMobile);

const { t } = useTrans();
const navStore = useNav();
const session = useSessionStore();
const favListStore = useFavLists();
const dialogStore = useDialogStore();

const navItems: MyProductsNavType[] = [
  {
    id: MyProductIds.VIEWED_PRODUCTS,
    title: 'shop.myProducts.nav.lastViewed',
    link: '/productlists/viewed-products',
  },
  {
    id: MyProductIds.ORDERED_PRODUCTS,
    title: 'shop.myProducts.nav.alreadyPurchased',
    link: '/productlists/ordered-products',
  },
  {
    id: MyProductIds.ORDER_ALARM,
    title: 'shop.orderAlarm.general.title',
    link: '/productlists/order-alarm',
  },
  {
    id: MyProductIds.AUTO_DELIVERY,
    title: 'shop.myProducts.nav.productSub',
    link: '/productlists/auto-delivery',
  },
];

const computedFavNavItems = computed(() => {
  const favListNavItems: MyProductsNavType[] =
    favListStore.myFavoriteList ||
    !session.isLoggedIn ||
    favListStore.favoriteLists.length == 0
      ? [
          {
            id: MyProductIds.FAV_LISTS,
            title: t(
              favListStore.myFavoriteList?.name ?? 'header.links.myFavorites',
            ),
            link: '/productlists/favorites',
          },
        ]
      : [];

  if (session.isLoggedIn) {
    favListStore.favoriteLists.map((list) => {
      favListNavItems.push({
        id: list.id,
        title: t(list.name),
        link: '/productlists/favorites/' + transformStringForUrl(list.id),
      });
    });
  }
  return favListNavItems;
});

const computedMobileNav = computed(() => {
  const mobileNavItems = navItems;
  computedFavNavItems.value.forEach((item) => {
    const findNavItem = mobileNavItems.find((mItem) => mItem.id === item.id);
    if (findNavItem) return;
    mobileNavItems.push(item);
  });
  return mobileNavItems;
});
</script>
